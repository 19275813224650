import { useEffect, useState } from "react";
import { Button, FormCheck, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { apiCall } from "../../helpers/axios";
import { useActivePage } from "../../helpers/utils";
import { IPage } from "../../types/page";

const CopyFlow = (props: { pages: IPage[] }) => {
  const { pages } = props;
  const copyItemsArr = [
    { key: 'welcome-msg', title: 'Welcome Message' },
    { key: 'menu', title: 'Menu' },
    { key: 'default-reply', title: 'Default Reply' },
  ];

  const activePage = useActivePage() as IPage;
  const [copyFrom, setCopyFrom] = useState(activePage?.fbId);
  const [copyTo, setCopyTo] = useState<string[]>([]);
  const [copyItems, setCopyItems] = useState<string[]>(copyItemsArr.map(a => a.key));
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCopyFrom(activePage?.fbId);
  }, [activePage?.fbId]);

  const copyItemsChange = (key: string) => (e: any) => {
    let items = [...copyItems];

    if (e.target.checked) {
      items.push(key);
    } else {
      items = items.filter(val => val !== key);
    }

    setError('');
    setCopyItems(items);
  }

  const copyToChange = (value: string) => (e: any) => {
    let items = [...copyTo];

    if (e.target.checked) {
      items.push(value);
    } else {
      items = items.filter(val => val !== value);
    }

    setError('');
    setCopyTo(items);
  }

  const copyFromChange = (e: any) => {
    setCopyFrom(e.target.value);
    setCopyTo([...copyTo.filter(a => a !== e.target.value)]);
  }

  const saveCopy = async () => {
    setError('');

    if (!copyTo.length) {
      setError('Select at least one page to copy to');
      return;
    }
    if (!copyItems.length) {
      setError('Select at least one item to copy');
      return;
    }
    const copyData = {
      copyFrom, copyTo, copyItems,
    };

    setLoading(true);
    try {
      const { data } = await apiCall.post('/flows/copy', copyData);
      toast.success(data.message);
      setCopyTo([]);
    } catch (error) {
      toast.error('Could not be completed');
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="card-body">
      <div className="mb-3 cp-flows">
        <h3>Copy Flows</h3>
        <p>This allows you to copy <strong className="text-blue">Welcome Message, Menu and Default Reply</strong> from one page to another.</p>
        <div className="table-responsive">
          <table className="table table-stripped">
            <thead>
              <tr>
                <th>Copy from</th>
                <th>Copy to</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormControl size="sm" as="select" onChange={copyFromChange}>
                    {pages.map((page, index) => (
                      <option key={`opt-${index}`} value={page.fbId} defaultValue={activePage?.fbId}>{page.name}</option>
                    ))}
                  </FormControl>
                </td>
                <td>
                  <div>
                    {pages.filter(page => page.fbId !== (copyFrom || activePage?.fbId)).map(page => (
                      <FormCheck
                        type='checkbox'
                        id={`cp-check-${page.fbId}`}
                        key={`cp-check-${page.fbId}`}
                        label={page.name}
                        checked={copyTo.includes(page.fbId)}
                        onChange={copyToChange(page.fbId)}
                      />
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="text-center pt-2">
            <div className="mb-1">
              {copyItemsArr.map(({ key, title }) => (
                <FormCheck inline
                  type='checkbox'
                  id={`check-${key}`}
                  key={`check-${key}`}
                  label={title}
                  checked={copyItems.includes(key)}
                  onChange={copyItemsChange(key)}
                />
              ))}
            </div>
            <p className="mb-1 text-white">{error && <small className="text-danger">{error}</small>}.</p>
            <Button onClick={saveCopy} className="px-4" variant="default" size="sm">
              {loading && <i className="fa fa-spinner fa-spin"></i>}
              <span> Copy</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CopyFlow;
