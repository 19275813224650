import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext, AppContextInterface } from '../../context';
import { apiCall } from '../../helpers/axios';
import { IPage } from '../../types/page';
import Loader from '../Loader/Loader';
import './ConnectPage.css';
import logout from '../../helpers/logout';
import { toast } from 'react-toastify';

export const ConnectPage: React.FC = () => {
  const { pages: connectedPages, setActivePage, activePage } = React.useContext(AppContext) as AppContextInterface;
  const [pages, setPages] = React.useState<IPage[]>([]);
  const [authUrl, setAuthUrl] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loggingOut, setLoggingOut] = React.useState(false);
  const [warning] = React.useState(searchParams.get('warning'));
  const navigate = useNavigate();

  const logoutUser = async (e: any) => {
    e.preventDefault();
    setLoggingOut(true);
    
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      toast.error('Could not be completed');
    }

    setLoggingOut(false);
  }

  const getUserPages = async () => {
    try {
      const { data: { pages } } = await apiCall.get('/pages');
      return pages;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  const loadLoginUrl = async () => {
    try {
      const { data } = await apiCall.get('/auth/facebook');
      const url = new URL(data.url);
      const state = url.searchParams.get('state');
      let stateObj: Record<string, string | boolean> = {};
      if (state) {
        try {
          stateObj = JSON.parse(state);
        } catch (_err) { }
        stateObj['connect'] = true;
      }
      url.searchParams.set('state', JSON.stringify(stateObj));
      return setAuthUrl(url.toString());
    } catch (err) {
      console.log(err)
    }
  }

  const gotoDash = () => {
    const page = activePage || connectedPages[0];
    setActivePage(page);
    navigate(`/dashboard?p=${page.fbId}`);
  }

  React.useEffect(() => {
    if (warning) {
      searchParams.delete('warning');
      setTimeout(() => setSearchParams(searchParams, { replace: true }), 500);
    }
    loadLoginUrl();
    getUserPages().then(pages => {
      setLoading(false);
      setPages(pages);
    });
  }, []);

  const populatePages = () => {
    return (
      <ul className="list-group list-group-flush list my--3">
        {
          pages.map((page, i) => (
            <li key={i} className="list-group-item px-0 py-2">
              <div className="row align-items-center">
                <div className="col-auto">
                  <span className="avatar rounded-circle">
                    <img alt="" src={page.photo} />
                  </span>
                </div>
                <div className="col ml--2 text-sm">{page.name} </div>
                <div className="col-auto">
                  <ConnectButton page={page} />
                </div>
              </div>
            </li>
          ))
        }
      </ul>
    )
  }

  return (
    <>
      {loading ? <Loader error={false} /> : (
        <div className="m-div">
          <div className="d-block modal" id="connectPageModal" tabIndex={1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body p-0">
                  <div className="mt-4 mb-3 text-center">
                    <img src="/assets/img/brand/suruchat-logo.svg" className="navbar-brand-img" alt="..." />
                    <h5 className="h3 text-center mb-0 mt-3">Connect a Page</h5>
                  </div>
                  <hr className="m-0" />
                  <div className="card mb-0">
                    <div className="card-body con-pages">
                      {!!warning && (
                        <div className="text-center text-warning mb-4 mt--3">
                          <small>{warning}</small>
                        </div>
                      )}
                      {!loading && !pages.length && (
                          <div className="text-center">
                            <div className="text-warning mb-4">
                              <span>You need to give </span>
                              <strong className="text-primary">Suruchat</strong>
                              <span> permission to Facebook pages you have admin access to in other to connect your pages.</span>
                            </div>

                            <a href={authUrl} className={(authUrl ? "" : "disabled ") + "btn btn-neutral btn-icon"}>
                              <span className="btn-inner--icon"><img src="/assets/img/icons/common/facebook.svg" /></span>
                              <span className="btn-inner--text">Continue with Facebook</span>
                            </a>

                            <div className="text-center text-muted my-4">
                              <h5>Redirection Notice!</h5>
                              <small>You will be redirected to facebook to grants us permissions to manage your pages, this is needed to automate your messages.</small><br />
                              <small>On Facebook, click on <strong className="text-primary">Edit Settings</strong> and select pages you have admin access to.</small>
                            </div>
                          </div>
                        )}
                      {populatePages()}
                    </div>
                  </div>
                  {!!pages.length && !!authUrl && (
                    <div className="mx-4 my-2">
                      <small>
                        <a href={authUrl}><strong>Click here</strong></a>
                        <span> to give permission to more pages on facebook. </span>
                      </small>
                      <small>On Facebook, click on <strong className="text-default">Edit Settings</strong> and select pages you have admin access to.</small>
                    </div>
                  )}
                  {!!pages.length && (
                    <div className="text-right mb-3">
                      <button onClick={gotoDash} disabled={!connectedPages.length} type="button" className="btn btn-sm px-5 btn-success m-2">Continue</button>
                    </div>
                  )}
                  <div className="text-right" style={{fontSize: '0.75rem'}}>
                    <a onClick={logoutUser} role='button' href="!#" className="nav-link">
                      <i className={(loggingOut ? 'fa-pulse' : '') + ` fa fa-power-off text-warning mr-1`}></i>
                      <span className="nav-link-text">Logout</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}


const ConnectButton = (props: React.PropsWithChildren<{ page: IPage }>) => {
  const { pages, setPages } = React.useContext(AppContext) as AppContextInterface;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [page, setPage] = React.useState(props.page);


  const connect = async () => {
    setLoading(true);
    try {
      await apiCall.post('/pages', { fbId: page.fbId });
      setLoading(false);
      setSuccess(true);

      setPages([...pages, { ...page, isConnected: true }]);
      setPage({ ...page, isConnected: true });
    } catch (err: any) {
      console.log(err);
      setLoading(false)
      alert(err.response?.data?.error || err.message);
    }
  }

  return (
    <button disabled={page.isConnected} type="button" className="btn btn-sm btn-primary" onClick={connect}>
      {success ? <i className="fa fa-check"></i> : ''}
      {loading ? <i className="fa fa-spinner fa-spin"></i> : ''}
      {' Connect' + (page.isConnected ? 'ed' : '')}
    </button>
  )
}