import './NotFound.css';

const NotFound = () => {
  return (
    <>
    <div className="not-found">
      <h4>Nothing Here!</h4>
    </div>
    </>
  )
}

export default NotFound;
