import React, { useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import TopNav from './components/Header/TopNav';
import Dashboard from './components/Dashboard/Dashboard';
import Loader from './components/Loader/Loader';
import { AppContext, AppContextInterface } from './context';
import { apiCall } from './helpers/axios';
import Flow from './components/Flow/Flow';
import FlowManager from './components/Flow/FlowManager';
import { ConnectPage } from './components/Dashboard/ConnectPage';
import Subscriber from './components/Subscriber/Subscriber';
import Broadcast from './components/Broadcast/Broadcast';
import NewBroadcast from './components/Broadcast/NewBroadcast';
import Profile from './components/Profile/Profile';
import NotFound from './components/404/NotFound';
import BroadcastMixer from './components/Broadcast/Mixer';
import { IPage } from './types/page';
import { useActivePage } from './helpers/utils';

function App() {
  const navigate = useNavigate();
  const activePage = useActivePage();
  const params = useParams<{ id: string }>();
  const { user, setPages, setActivePage } = React.useContext(AppContext) as AppContextInterface;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [authUrl, setAuthUrl] = React.useState<string>('');

  const loadLoginUrl = async () => {
    try {
      const { data } = await apiCall.get('/auth/facebook');
      return setAuthUrl(data.url);
    } catch (err) {
      console.log(err)
    }
  }

  const getUserPages = async () => {
    try {
      const { data: { pages } } = await apiCall.get('/pages?status=connected');
      return pages;
    } catch (err) {
      setError(true);
      return [];
    }
  }

  const qParams = new URLSearchParams(window.location.search);
  const pageId = qParams.get('p');
  const showTopBar = params.id !== 'profile';

  React.useEffect(() => {
    if (!user) {
      return navigate('/login/facebook');
    }

    loadLoginUrl().catch();

    getUserPages().then((pages: IPage[]) => {
      setLoading(false);
      if (!pages.length) {
        return navigate('/connect-pages');
      }
      setPages(pages);

      const anActivePage = pageId ? pages.find(p => p.fbId === pageId) : pages[0];
      if (!anActivePage) {
        return navigate('/page-not-found');
      }
      setActivePage(anActivePage);

      let location = window.location.pathname;
      if (location === '/' || !pageId) {
        location = location === '/' ? '/dashboard' : location;
        qParams.set('p', anActivePage.fbId);
        return navigate(`${location}?${qParams.toString()}`);
      }
    });
  }, [])

  return (
    <>
      {
        loading ? <Loader error={error} /> : <div className="App">
          <Sidebar />

          <div className="main-content" id="panel">
            {showTopBar && (
              <>
                <TopNav />
                {activePage?.needsReconnection && (
                  <h3 style={{ backgroundColor: '#f5365c29' }} className="text-red m-0 p-3 text-center border-bottom">
                    <span>Page disconnected: SuruChat permission to manage your page has been revoked.</span>
                    <small className='ml-2'>
                      <a href={authUrl}><strong>Click here</strong></a>
                      <span> to reconnect your pages. </span>
                    </small>
                  </h3>
                )}
                {activePage?.policyActions?.filter(a => a.action !== 'disconnect').map(({ action, reason }, i) => (
                  <div key={i} style={{ backgroundColor: '#f5365c29' }} className="text-red px-3 text-center py-1 border-bottom"><small>Page {action}ed: {reason}</small></div>
                ))}
              </>
            )}

            <Routes>
              <Route path='/' element={<Link to='/dashboard' />} />
              <Route path='profile' element={<Profile />} />
              <Route path='connect-pages' element={<ConnectPage />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='subscribers' element={<Subscriber />} />
              <Route path='broadcasts'>
                <Route path='' element={<Broadcast />} />
                <Route path='mixer' element={<BroadcastMixer />} />
                <Route path=':flowId' element={<NewBroadcast />} />
              </Route>
              <Route path='flows'>
                <Route path='' element={<Flow />} />
                <Route path='create' element={<FlowManager type="new-flow" />} />
                <Route path='welcome-message' element={<FlowManager type="welcome-msg" />} />
                <Route path='sticky-menu' element={<FlowManager type="menu" />} />
                <Route path='default-reply' element={<FlowManager type="default-reply" />} />
                <Route path=':flowId' element={<FlowManager type="view-flow" />} />
                <Route path=':flowId/edit' element={<FlowManager type="edit-flow" />} />
              </Route>
              <Route path='*' element={<NotFound />} />
            </Routes>
          </div>
        </div>
      }
    </>
  );
}

export default App;
