import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Row } from 'react-bootstrap';
import { apiCall } from '../../helpers/axios';
import { IFlow, TFlow } from '../../types/page';
import FlowCard from '../Flow/FlowCard/FlowCard';
import CenterLoader from '../Loader/Loader2';
import { MESSAGE_TAGS } from '../../constants';
import { toast } from 'react-toastify';
import { DragDropContext } from 'react-beautiful-dnd';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { useActivePage } from '../../helpers/utils';
import NotFound from '../404/NotFound';

const NewBroadcast: React.FC = () => {
  const activePage = useActivePage();
  const [flow, setFlow] = React.useState<TFlow | null>(null);
  const [subscribersCount, setSubscribersCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [schedule, setSchedule] = React.useState(false);
  const [sendAt, setSenAt] = React.useState(moment());
  const [tagIndex, setTagIndex] = React.useState(Math.floor(Math.random() * 2) + 0);
  const params = useParams<{ flowId: string }>();
  const navigate = useNavigate();

  const tags = MESSAGE_TAGS;
  const setTag = (e: any) => {
    setTagIndex(e.target.value);
  }

  const sendBroadcast = async () => {
    if (schedule && moment().diff(sendAt, 'milliseconds') > 0) {
      return toast.error('Cannot schedule for a past time.');
    }

    try {
      setIsSaving(true);
      const pageId = activePage?.fbId;
      const { data } = await apiCall.post(`/broadcasts`, {
        pageId, flowId: params.flowId, tag: tags[tagIndex].name,
        sendAt: schedule ? sendAt.toISOString() : undefined,
      });
      toast.success('Message sent successfully.');
      navigate(`/broadcasts?p=${activePage?.fbId}`);
      return data.broadcast;
    } catch (error) {
      console.log(error);
      toast.error('Error coccurred while processing.');
      return null;
    } finally {
      setIsSaving(false)
    }
  }

  const onSchedule = (type: string) => (e: any) => {
    const newSendAt = moment(sendAt);
    const { years, months, date, hours, minutes } = moment(type === 'date' ? e.target.value : e).toObject();
    const seconds = 0;

    if (type === 'date') {
      newSendAt.set({ years, months, date, seconds });
    } else {
      newSendAt.set({ hours, minutes, seconds });
    }

    setSenAt(newSendAt);
  }

  const getFlow = async () => {
    try {
      const { data } = await apiCall.get(`/flows/${params.flowId}`);
      return data.flow;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  const getSubscribersCount = async () => {
    try {
      const pageId = activePage?.fbId;
      const { data } = await apiCall.get(`/subscribers/count?pageId=${pageId}`);
      return data.count;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  React.useEffect(() => {
    let isCancelled = false;

    Promise.all([getFlow(), getSubscribersCount()])
      .then(([flowData, countData]) => {
        const flow = flowData as IFlow;
        const count = countData as number;
        if (!isCancelled && flow) {
          setFlow({
            name: flow.name,
            type: flow.type,
            pageId: flow.pageId,
            templates: flow.templates,
          });
          setSubscribersCount(count);
        }
        setIsLoading(false);
        isCancelled = false;
      });

    return () => {
      isCancelled = true;
    };
  }, [activePage?.fbId])

  const BroadcastPage = () => {
    if (!flow) {
      return <NotFound />
    }

    return (
      <Row>
        <div className="col-12">
          <h1>{flow.name}</h1><hr className="mt-3" />
        </div>
        <div className={"col-lg-8 flow-view"}>
          <DragDropContext onDragEnd={() => { }}>
            <div className="col-lg-8 mx-auto">
              <FlowCard flow={flow} isView={true} />
            </div>
          </DragDropContext>
        </div>

        <div className="col-lg-4 text-centr">
          <p>
            Send broadcast to <strong>{subscribersCount}</strong> subscriber(s)
          </p>
          <div>
            <select onChange={setTag} defaultValue={tagIndex} className="form-control w-75" name="tag">
              {
                tags.map((tag, i) => (
                  <option key={i} value={i}>{tag.name}</option>
                ))
              }
            </select>
            <p className="mt-3">
              <small>{tags[tagIndex].description}</small>
            </p>
          </div>
          <div>
            <input className="mr-1" type="checkbox" id="scheduleBC" checked={schedule} onChange={() => setSchedule(!schedule)} />
            <label htmlFor="scheduleBC">Schedule for later?</label>
            {schedule && <div className="pl-3 mb-3">
              <div className="mb-2">
                <span>Date: </span>
                <input onChange={onSchedule('date')} className="date-inp" type="date" name="" id="" defaultValue={sendAt.format('YYYY-MM-DD')} />
              </div>
              <div>
                <span>Time: </span>
                <TimePicker minuteStep={5} onChange={onSchedule('time')} allowEmpty={false} showSecond={false} use12Hours={true} defaultValue={sendAt} />
              </div>
            </div>}
          </div>
          <Button onClick={sendBroadcast} variant="success" className="px-5 m-2" disabled={subscribersCount < 1 || isSaving}>
            <i className={"fa fa-spinner fa-spin" + (isSaving ? '' : ' d-none')}></i>
            <i className="fa fa-bullhorn"></i>
            <span> Send</span>
          </Button>
        </div>
      </Row>
    )
  }

  return (
    <div className="container-fluid content-area mt-3">
      {
        isLoading ? <CenterLoader /> : BroadcastPage()
      }
    </div>
  )
}

export default NewBroadcast;
