import * as React from "react";
import Helmet from 'react-helmet';
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { apiCall } from "../../helpers/axios";
import './Login.css';

const Password: React.FC = () => {
  const errMsg = new URLSearchParams(window.location.search).get('error') || '';
  const resetToken = new URLSearchParams(window.location.search).get('token') || '';
  const [error, setError] = React.useState<string>(errMsg);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loginData, setLoginData] = React.useState({
    email: '', password: '', password2: '',
  });
  const navigate = useNavigate();

  let btnText = ' Send Reset Link';
  let resetText = 'Enter your email address.';
  if (resetToken) {
    btnText = ' Change Password';
    resetText = 'Enter a new password.';
  }

  const setInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  }

  const submitForm = async (e: any) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    let errMsg;

    try {
      if (resetToken) {
        if (!loginData.password || !loginData.password2) {
          errMsg = 'Enter your password and confirm password.';
        } else if (loginData.password !== loginData.password2) {
          errMsg = 'Passwords does not match.';
        }
      } else {
        if (!loginData.email) {
          errMsg = 'Enter your email address.';
        }
      }

      if (errMsg) {
        setIsLoading(false);
        return setError(errMsg);
      }

      const url = resetToken ? '/auth/password-reset' : '/auth/password-request';
      const formData = resetToken ? {
        password: loginData.password,
        token: resetToken,
      } : { email: loginData.email };

      const { data } = await apiCall.post(url, formData);
      toast.success(data.message);
      navigate('/login');
    } catch (err: any) {
      const errFields = Object.values(err?.response?.data?.fields || {});
      errFields.push(err?.response?.data?.error)
      setError(errFields[0] as string);
      console.log(err?.response?.data);
      setIsLoading(false);
    }
  }

  const getFormInputs = () => {
    return (<>
      {
        resetToken
          ? <>
            <div className="mb-3 form-group">
              <div className="input-group-alternative input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="ni ni-lock-circle-open"></i></span>
                </div>
                <input name="password" onChange={setInput} value={loginData.password} placeholder="Password" type="password" className="form-control" />
              </div>
            </div>
            <div className="form-group">
              <div className="input-group-alternative input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="ni ni-lock-circle-open"></i></span>
                </div>
                <input name="password2" onChange={setInput} value={loginData.password2} placeholder="Confirm Password" type="password" className="form-control" />
              </div>
            </div>
          </>
          : <>
            <div className="mb-3 form-group">
              <div className="input-group-alternative input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                </div>
                <input name="email" onChange={setInput} value={loginData.email} placeholder="Email" type="email" className="form-control" />
              </div>
            </div>
          </>
      }
    </>)
  }

  return (
    <>
      <Helmet>
        <style>{'body { background-color: #c3c3c3; }'}</style>
      </Helmet>
      <div className="main-content">

        <div className="header bg-gradient-primary py-5 py-lg-6 pt-lg-6">
          <div className="container">
            <div className="header-body text-center mb-7">
              <div className="row justify-content-center">
              </div>
            </div>
          </div>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </div>

        <div className="container mt--8 pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="card bg-secondary border-0 mb-0">
                <div className="card-header bg-transparent py-4">
                  <div className="text-muted text-center mt-2 mb-3">
                    <img src="/assets/img/brand/suruchat-logo.svg" className="navbar-brand-img" alt="..." />
                    <hr />
                    <h3>Reset Your Password</h3>
                    <small>{resetText}</small>
                  </div>
                  <form onSubmit={submitForm}>
                    <div className="col-10 mx-auto">
                      {getFormInputs()}
                      <div className="text-center">
                        <small className="text-danger">{error}</small><br />
                      </div>
                      <div className="btn-wrapper text-center py-3">
                        <button type="submit" className="btn btn-primary px-5">
                          <i className={`fa fa-spinner fa-spin ${isLoading ? '' : 'd-none'}`}></i>
                          {btnText}
                        </button>
                      </div>
                      <div hidden={!!resetToken} className="btn-wrapper text-right py-3">
                        <Link className="btn btn-sm" to="/login">Login</Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Password;
