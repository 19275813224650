import * as React from "react";
import Helmet from 'react-helmet';
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { apiCall } from "../../helpers/axios";
import './Login.css';

const Login: React.FC = () => {
  const errMsg = new URLSearchParams(window.location.search).get('error') || '';
  const [error, setError] = React.useState<string>(errMsg);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loginData, setLoginData] = React.useState({
    email: '', password: '',
  });
  const navigate = useNavigate();

  const setInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  }

  const loginUser = async (e: any) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      if (!loginData.password || !loginData.email) {
        setIsLoading(false);
        return setError('Enter your email and password.');
      }

      await apiCall.post('/auth/login', loginData);
      navigate('/login/facebook');
    } catch (err: any) {
      setError(err?.response?.data?.error);
      setIsLoading(false);
    }
  }

  return (
    <>
      <Helmet>
        <style>{'body { background-color: #c3c3c3; }'}</style>
      </Helmet>
      <div className="main-content">

        <div className="header bg-gradient-primary py-5 py-lg-6 pt-lg-6">
          <div className="container">
            <div className="header-body text-center mb-7">
              <div className="row justify-content-center">
              </div>
            </div>
          </div>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </div>

        <div className="container mt--8 pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="card bg-secondary border-0 mb-0">
                <div className="card-header bg-transparent py-4">
                  <div className="text-muted text-center mt-2 mb-3">
                    <img src="/assets/img/brand/suruchat-logo.svg" className="navbar-brand-img" alt="..." />
                    <hr />
                    <h3>Login to Your Account</h3>
                    <small>Sign in with your email and password</small>
                  </div>
                  <form onSubmit={loginUser}>
                    <div className="col-10 mx-auto">
                      <div className="mb-3 form-group">
                        <div className="input-group-alternative input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                          </div>
                          <input name="email" onChange={setInput} value={loginData.email} placeholder="Email" type="email" className="form-control" />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group-alternative input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="ni ni-lock-circle-open"></i></span>
                          </div>
                          <input name="password" onChange={setInput} value={loginData.password} placeholder="Password" type="password" className="form-control" />
                        </div>
                      </div>
                      <div className="text-center">
                        <small className="text-danger">{error}</small><br />
                      </div>
                      <div className="btn-wrapper text-center py-3">
                        <button type="submit" className="btn btn-primary px-5">
                          <i className={`fa fa-spinner fa-spin ${isLoading ? '' : 'd-none'}`}></i>
                           Sign in
                        </button>
                      </div>
                      <div className="btn-wrapper text-right py-3">
                        <Link className="btn btn-sm" to="/password-reset">Forgot Password</Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
