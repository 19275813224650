import * as React from "react";
import Helmet from 'react-helmet';
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { TEST_ACCOUNT_IDS } from "../../constants";
import { apiCall } from "../../helpers/axios";
import { IUser } from "../../types/user";
import Loader from "../Loader/Loader";
import './Login.css';

const LoginFB: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [authUrl, setAuthUrl] = React.useState<string>('');
  const [termsAgree, setTermsAgree] = React.useState<boolean>(false);
  const [isTestUser, setIsTestUser] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [users, setUsers] = React.useState([] as IUser[]);

  const errMsg = searchParams.get('error');
  const [error, setError] = React.useState(errMsg);
  const [connect, setConnect] = React.useState(!!errMsg);
  const isATestUser = (id: string) => TEST_ACCOUNT_IDS.includes(id);

  const loadLoginUrl = async () => {
    try {
      const { data: { users, url } } = await apiCall.get('/auth/facebook');
      setIsLoading(false);
      setUsers(users);
      if (users.length) {
        setIsTestUser(isATestUser(users[0].accountId));
      }
      if (error) {
        searchParams.delete('error');
        setTimeout(() => setSearchParams(searchParams, { replace: true }), 500);
      }
      setConnect(!!error || !users.length);
      return setAuthUrl(url);
    } catch (err) {
      const message = 'Sorry! Login Broken, we are working on a fix.';
      setError(message);
    }
  }

  const loginUser = (user: IUser) => async () => {
    setIsLoading(true);
    navigate(`/auth-callback?fbId=${user.fbId}`);
  }

  const toggleConnect = () => setConnect(!connect);  

  React.useEffect(() => {
    loadLoginUrl();
  }, []);

  React.useEffect(() => {
    if (!users.length || !isTestUser) return;
    loginUser(users[0])();
  }, [isTestUser]);

  const onAgree = () => setTermsAgree(!termsAgree);

  const AccountConnect = () => {
    return (
      <div className="card bg-secondary border-0 mb-0">
        <div className="card-header bg-transparent py-4">
          <div className="text-muted text-center mt-2 mb-3">
            <img src="/assets/img/brand/suruchat-logo.svg" className="navbar-brand-img" alt="..." />
            <hr />
            <h3>Connect Your Facebook Profile</h3>
            <small>Sign in with your facebook account to start setting up your bot</small>
          </div>
          <div className="custom-control text-center pt-3 custom-control-alternative custom-checkbox">
            <input checked={termsAgree} onChange={onAgree} className="custom-control-input" id=" customCheckLogin" type="checkbox" />
            <label className="custom-control-label" htmlFor=" customCheckLogin">
              <span className="text-muted">
                I agree to SuruChat's <a href="/terms-and-condition">Terms & Condition</a> and <a href="/privacy-policy">Privacy Policy</a>
              </span>
            </label>
          </div>
          <div className="btn-wrapper text-center pt-3">
            <a href={authUrl} className={((authUrl && termsAgree) ? "" : "disabled ") + "btn btn-neutral btn-icon"}>
              <span className="btn-inner--icon"><img src="/assets/img/icons/common/facebook.svg" /></span>
              <span className="btn-inner--text">Continue with Facebook</span>
            </a><br />
            <small className="text-danger">{error || '.'}</small>
          </div>
          <div hidden={!users.length || isTestUser} className="text-right mt-2 mb--2">
            <button onClick={toggleConnect} className="btn btn-secondary btn-sm">Existing Accout</button>
          </div>
        </div>
        <div className="card-body px-lg-5 pb-5 pt-4">
          <div className="text-center text-muted mb-4">
            <h5>Redirection Notice!</h5>
            <small>You will be redirected to facebook to grants us permissions to manage your pages, this is needed to automate your messages.</small>
            <small className="text-warning"> Select only pages you have admin access to.</small><br />
            <small>For returning user, to give access to more pages: On Facebook, click on <strong className="text-primary">Edit Settings</strong> and select pages you have admin access to.</small>
          </div>
        </div>
      </div>
    )
  }

  const AccountContinue = () => {
    return (
      <div className="card bg-secondary border-0 mb-0">
        <div className="card-header bg-transparent py-4">
          <div className="text-muted text-center mt-2 mb-3">
            <img src="/assets/img/brand/suruchat-logo.svg" className="navbar-brand-img" alt="..." />
            <hr />
            <h3>Continue With...</h3>
            <small>Continue with your existing account(s)</small>
          </div>
          <div>
            <ul className="list-group list-group-flush list my-3">
              {
                users.map((user, i) => (
                  <li onClick={loginUser(user)} key={i} className="user-acc d-block p-2">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-auto">
                        <span className="avatar rounded-circle">
                          <img alt="" src={user.photo} />
                        </span>
                      </div>
                      <div className="col-auto ml--2">
                        <h4 className="mb-0 text-primary">
                          <span>{user.lastName} {user.firstName} </span><br/>
                          { user?.email && <small style={{fontSize: '60%'}}> ({user?.email})</small> }
                        </h4>
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="text-right">
            <button onClick={toggleConnect} className="btn btn-secondary btn-sm">New Accout</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {
        isLoading ? <Loader error={false} /> : <>
          <Helmet>
            <style>{'body { background-color: #c3c3c3; }'}</style>
          </Helmet>
          <div className="main-content">

            <div className="header bg-gradient-primary py-5 py-lg-6 pt-lg-6">
              <div className="container">
                <div className="header-body text-center mb-7">
                  <div className="row justify-content-center">
                  </div>
                </div>
              </div>
              <div className="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
              </div>
            </div>

            <div className="container mt--8 pb-5">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  {
                    connect ? <AccountConnect /> : <AccountContinue />
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default LoginFB;
