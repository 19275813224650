import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { apiCall } from '../../helpers/axios';
import HeaderStat from './HeaderStat';
import { useActivePage } from '../../helpers/utils';
import { TInsights, TStat, TSubscriberStat } from '../../types/page';
import { AppContext, AppContextInterface } from '../../context';

const getDefaultGraphData = (name: string) => ({
  labels: [] as number[],
  datasets: [
    {
      label: `No. of ${name}`,
      data: [] as number[],
      fill: true,
      backgroundColor: 'rgb(147, 163, 177)',
      borderColor: 'rgba(147, 163, 177, 0.2)',
    },
  ],
});

const getDefaultGraphOptions = (name: string) => ({
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'Day of the month'
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: `${name} Count`
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
});

const Dashboard: React.FC = () => {
  const activePage = useActivePage();
  const { canViewInsights, hasInsightsPerm } = React.useContext(AppContext) as AppContextInterface;
  const [isMonth, setIsMonth] = React.useState(true);
  const [isLoadingInsight, setIsLoadingInsight] = React.useState(true);
  const [graphValues, setGraphValues] = React.useState<TSubscriberStat>({ monthStat: [], dayStat: [] });
  const [subscriberGraphData, setSubscriberGraphData] = React.useState(getDefaultGraphData('Subscribers'));
  const [subscriberGraphOptions, setSubscriberGraphOptions] = React.useState(getDefaultGraphOptions('Subscribers'));
  const [impressionGraphData, setImpressionGraphData] = React.useState(getDefaultGraphData('Impressions'));
  const [impressionGraphOptions, setImpressionGraphOptions] = React.useState(getDefaultGraphOptions('Impressions'));
  const [insightData, setInsightData] = React.useState<TInsights>({
    monthStats: [], monthCount: 0, weekCount: 0, dayCount: 0,
  });

  const getGraphData = async () => {
    try {
      const pageId = activePage?.fbId;
      const { data: { monthStat, dayStat } }: { data: TSubscriberStat } = await apiCall.get(`/subscribers/graph?pageId=${pageId}`);

      setGraphValues({ monthStat, dayStat });
      setGraph(dayStat, false);
    } catch (error) {
      console.log(error);
    }
  }

  const setGraph = (data: TStat[], m = true) => {
    setIsMonth(m);

    const xKey = m ? 'day' : 'hour';
    const labelString = m ? 'Day of the month' : 'Hour of the day';

    if (data.length) {
      const graph = { ...subscriberGraphData };
      graph.labels = data.map(a => a[xKey] as number);
      graph.datasets[0].data = data.map(a => a.count);
      setSubscriberGraphData(graph);

      const opts = { ...subscriberGraphOptions };
      opts.scales.xAxes[0].scaleLabel.labelString = labelString;
      setSubscriberGraphOptions(opts);
    }
  }

  const getPageInsights = async () => {
    if (!canViewInsights || !hasInsightsPerm) return;
    try {
      const pageId = activePage?.fbId;
      const { data }: { data: { insights: TInsights } } = await apiCall.get(`/pages/insights?pageId=${pageId}`);

      const graph = { ...impressionGraphData };
      graph.labels = data.insights.monthStats.map(a => a.day);
      graph.datasets[0].data = data.insights.monthStats.map(a => a.count);
      setImpressionGraphData(graph)
      setInsightData(data.insights);

      setIsLoadingInsight(false);
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    if (!activePage) return;
    getGraphData();
    getPageInsights();
  }, [activePage?.fbId])

  const renderPageImpressionStat = () => {
    if (!canViewInsights || !hasInsightsPerm) return null;
    return (
      <div className="col-xl-12 col-md-12">
        <div className="card card-stats bg-darkk text-muted">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h5 className="card-title text-uppercase text-muted mb-0">
                  <span>Page Unique Posts Impressions </span>
                  {isLoadingInsight && (<span className="text-white"><i className='fa fa-circle-notch fa-spin'></i></span>)}
                </h5>
                <div className='row text-center'>
                  <div className='col-4'>
                    <span className="h2 font-weight-bold text-light mb-0">{insightData.dayCount.toLocaleString()}</span>
                    <p className="mb-0 text-sm">
                      <span className="mr-2"><i className="fa fa-cube"></i></span>
                      <span className="text-nowrap">Today</span>
                    </p>
                  </div>
                  <div className='col-4'>
                    <span className="h2 font-weight-bold text-light mb-0">{insightData.weekCount.toLocaleString()}</span>
                    <p className="mb-0 text-sm">
                      <span className="mr-2"><i className="fa fa-cube"></i></span>
                      <span className="text-nowrap">This Week</span>
                    </p>
                  </div>
                  <div className='col-4'>
                    <span className="h2 font-weight-bold text-light mb-0">{insightData.monthCount.toLocaleString()}</span>
                    <p className="mb-0 text-sm">
                      <span className="mr-2"><i className="fa fa-cube"></i></span>
                      <span className="text-nowrap">This Month</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <div className="icon icon-shape bg-gradient-white text-red rounded-circle shadow">
                  <i className="fa fa-chart-bar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderSubscriberGraph = () => {
    return (
      <div className="col-xl-12">
        <div className="card graph bg-darkk">
          <div className="card-header bg-transparent pb-1">
            <div className="row align-items-center">
              <div className="col">
                <h5 className="h3 text-muted mb-0">Subscribers Count</h5>
              </div>
              <div className="col">
                <ul className="nav nav-pills justify-content-end">
                  <li className="nav-item mr-2 mr-md-0">
                    <button
                      onClick={() => setGraph(graphValues.dayStat, false)}
                      disabled={!isMonth}
                      className={`b-0 nav-link py-2 px-3 ${!isMonth && 'active'}`}
                    >
                      <span className="d-none d-md-block">Day</span>
                      <span className="d-md-none">D</span>
                    </button>
                  </li>
                  <li className="nav-item mr-2 mr-md-0">
                    <button
                      onClick={() => setGraph(graphValues.monthStat)}
                      disabled={isMonth}
                      className={`b-0 nav-link py-2 px-3 ${isMonth && 'active'}`}
                    >
                      <span className="d-none d-md-block">Month</span>
                      <span className="d-md-none">M</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="chart">
              <Line data={subscriberGraphData} options={subscriberGraphOptions} height={100} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderPageImpressionGraph = () => {
    if (!canViewInsights || !hasInsightsPerm) return null;
    return (
      <div className="col-xl-12">
        <div className="card graph bg-darkk">
          <div className="card-header bg-transparent pb-1">
            <div className="row align-items-center">
              <div className="col">
                <h5 className="h3 text-muted mb-0">Page Posts Impressions</h5>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="chart">
              <Line data={impressionGraphData} options={impressionGraphOptions} height={100} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const DashboardDetail = () => {
    return (
      <div className="container-fluid mt--6">
        <div className="row">
          {renderPageImpressionStat()}
          {renderSubscriberGraph()}
          {renderPageImpressionGraph()}
        </div>
      </div>
    )
  }

  return (
    <>
      <HeaderStat />
      <DashboardDetail />
    </>
  )
}

export default Dashboard;
