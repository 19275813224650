export const API_BASE_URL = 'https://api.suruchat.com/api';

export const MESSAGE_TAGS = [
  {
    name: 'CONFIRMED_EVENT_UPDATE',
    description: 'Send users reminders or updates for an event they have registered for',
  },
  {
    name: 'POST_PURCHASE_UPDATE',
    description: 'Notify users of an update on a recent purchase.',
  },
  {
    name: 'ACCOUNT_UPDATE',
    description: 'Notify users of a non-recurring change to their application or account.',
  },
];

export const TEST_ACCOUNT_IDS = ['6206cc7d59be920028ce163e'];
