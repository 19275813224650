import React from 'react';
import { AppContext, AppContextInterface } from '../context';
import { IPage } from '../types/page';

export const useActivePage = () => {
  const { pages, setActivePage, activePage } = React.useContext(AppContext) as AppContextInterface;

  const qParams = new URLSearchParams(window.location.search);
  const pageId = qParams.get('p');
  const page = pages.find(page => page.fbId === pageId) || activePage;

  React.useEffect(() => {
    if (page && page?.fbId !== activePage?.fbId) {
      setActivePage(page);
    }
  });

  return page;
}

export const SetActivePage = (page: IPage) => {
  const qParams = new URLSearchParams(window.location.search);
  qParams.set('p', page.fbId);
}

export const isValidateUrl = (url = '') => {
  if (url.substring(0, 4) !== 'http') url = `http://${url}`;
  try { return !!new URL(url); } catch (_e) { return false; }
}
