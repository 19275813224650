import * as React from 'react';
import './Loader.css';

const CenterLoader: React.FC = () => {
  return (
    <div className="content-loader">
      <i className="fa fa-2x fa-life-ring fa-spin"></i>
    </div>
  )
}

export default CenterLoader;
