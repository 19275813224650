import * as React from 'react';
import 'react-popper-tooltip/dist/styles.css';
import '../Flow.css';


const FlowTextarea = (props: {
  temIndex: number,
  name?: string;
  value?: string;
  placeholder?: string;
  taClass?: string;
  dvClass?: string;
  hidden?: boolean;
  isView?: boolean,
  setInput: (temIndex: number) => (e: any) => void;
}) => {
  const { temIndex, name, value, placeholder, taClass, dvClass, hidden, isView, setInput } = props;

  const iniHeight = name === 'title' ? 38 : 48;
  const [inpHeight, setInpHeight] = React.useState(iniHeight);
  const ref = React.createRef<HTMLDivElement>();

  const tagsToReplace: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;'
  };

  const replaceTag = (tag: string): string => {
    return tagsToReplace[tag] || tag;
  }

  const parseTag = (value: string = '') => {
    return (value || '')
      .replace(/[&<>]/g, replaceTag)
      .replace(
        /(\{)(First Name|Full Name|Last Name)(\})/ig,
        '<span class="sur-tag"><span>$1</span><span>$2</span><span>$3</span></span>',
      )
      .replace(/\n/g, '<br/>') + '\n';
  }

  React.useEffect(() => {
    setInpHeight(ref.current?.clientHeight as number);
  }, [value]);

  return (
    <>
      {!hidden && (
        <div className={"sur_textarea " + (dvClass || '')}>
          {!isView && (
            <textarea
              key={`tex-area-${temIndex}-${name}`}
              spellCheck={false}
              style={{ height: inpHeight + 'px' }}
              value={value || ''}
              onChange={setInput(temIndex)}
              className={"flow-title mb-0 w-100 " + (taClass || '')}
              name={name}
              placeholder={placeholder} />
          )}
          <div ref={ref} dangerouslySetInnerHTML={{ __html: parseTag(value) }} className={"sur_textarea_div flow-title mb-0 w-100 " + (taClass || '')}></div>
        </div>
      )}
    </>
  )
}

export default FlowTextarea;
