import Joi from 'joi';

export const templateValidator = Joi.object().keys({
  _id: Joi.string().optional(),
  image: Joi.string().optional(),
  imageB64: Joi.string().optional(),
  type: Joi.string().required().valid('text', 'generic', 'button'),
  action: Joi.object().keys({
    url: Joi.string().required().allow('').label('cardUrl'),
    type: Joi.string().optional(),
  }).optional(),
  title: Joi.string().when('type', {
    is: 'generic',
    then: Joi.required(),
    otherwise: Joi.optional(),
  }),
  subtitle: Joi.string().allow('').optional(),
  photo: Joi.string().optional(),
  text: Joi.string().optional().label('message'),
  buttons: Joi.array().optional(),
  imageAspectRatio: Joi.string().optional(),
  delay: Joi.number().optional(),
});
