import * as React from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { IPage, TFlow, TFlowTemplate } from '../../types/page';
import { apiCall } from '../../helpers/axios';
import { useNavigate, useParams } from 'react-router-dom';
import CenterLoader from '../Loader/Loader2';
import { templateValidator } from './FlowCard/validator';
import FlowCard from './FlowCard/FlowCard';
import { isValidateUrl, useActivePage } from '../../helpers/utils';
import 'react-popper-tooltip/dist/styles.css';
import './Flow.css';
import NotFound from '../404/NotFound';
import { AppContext, AppContextInterface } from '../../context';

type TFlowTypes = 'new-flow' | 'view-flow' | 'edit-flow' | 'welcome-msg' | 'menu' | 'default-reply';

const FlowManager = (props: { type: TFlowTypes }) => {
  const activePage = useActivePage();
  const typeTitles = {
    'view-flow': '',
    'edit-flow': 'Editing a flow',
    'new-flow': 'Create new flow',
    'welcome-msg': 'Welcome Message',
    'menu': 'Sticky Menu',
    'default-reply': 'Default Reply',
  };
  const flowActions = ['view-flow', 'edit-flow', 'new-flow'];
  const isFlow = flowActions.includes(props.type);
  const isMenu = props.type === 'menu';
  const isView = props.type === 'view-flow';
  const isTypedFlow = ['welcome-msg', 'default-reply', 'menu'].includes(props.type);
  const isEdit = isTypedFlow || ['edit-flow'].includes(props.type);
  const readOnly = isView;

  type TTFlow = TFlow & { alwaysReply: boolean };
  const initialFlow: TTFlow = {
    name: isFlow ? '' : typeTitles[props.type],
    pageId: activePage?.fbId as string,
    type: 'flow',
    templates: [],
    alwaysReply: activePage?.alwaysReply || false,
  };

  const navigate = useNavigate();
  const params = useParams<{ flowId: string }>();
  const { setActivePage } = React.useContext(AppContext) as AppContextInterface;
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [uploadsPercent, setUploadsPercent] = React.useState<number[]>([]);
  const [tempErrors, setTempError] = React.useState<string[]>([]);
  const [error, setError] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [flow, setFlow] = React.useState(initialFlow);
  const [replaceLink, setReplaceLink] = React.useState('');
  const [replaceLinkError, setReplaceLinkError] = React.useState('');

  const getFlow = async (): Promise<TFlow | null> => {
    try {
      if (props.type === 'new-flow') {
        return {
          ...flow,
          templates: [{
            type: 'generic',
            action: { url: '' },
            buttons: [],
          }]
        };
      }
      const pageId = isFlow ? '' : activePage?.fbId;
      const { data } = await apiCall.get(`/flows/${params.flowId || props.type}?pageId=${pageId}`);
      return data.flow;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  React.useEffect(() => {
    let isCancelled = false;

    getFlow().then((flow) => {
      if (!isCancelled && flow) {
        setFlow({
          name: flow.name,
          type: flow.type,
          pageId: flow.pageId,
          templates: flow.templates.map(tem => ({
            ...tem,
            action: { url: '', ...tem.action },
            delay: (tem.delay && tem.delay !== 0) ? tem.delay / 1000 : 0,
          })),
          alwaysReply: activePage?.alwaysReply || false,
        });
      }
      isCancelled = false;
    }).finally(() => {
      setTimeout(() => setIsLoading(false), 110);
    });

    return () => {
      isCancelled = true;
    };
  }, [activePage?.fbId]);

  const clearErrors = () => {
    setNameError('');
    setError('');
    setTempError([]);
  }

  const setButton = (temIndex: number, btnIndex: number, type: 'title' | 'url') => (e: React.ChangeEvent<HTMLInputElement>) => {
    const tempFlow = { ...flow };
    const buttons = tempFlow.templates[temIndex]?.buttons;
    if (buttons && buttons[btnIndex]) {
      // @ts-ignore
      buttons[btnIndex][type] = e.target.value;
    }

    clearErrors();
    setFlow({ ...tempFlow });
  }

  const removeImage = (temIndex: number) => (e: any) => {
    e.preventDefault();
    const tempFlow = { ...flow };
    const template = tempFlow.templates[temIndex];
    if (template) {
      template.photo = '';
      template.image = '';
      template.imageB64 = '';
      setFlow({ ...tempFlow });
    }
  }

  const setImage = (temIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = (e.target.files || [])[0];
    if (!file) return;

    if (file.size > 3200000) {
      // @ts-ignore
      e.target.value = null;
      return toast.error('Image size should not be greater than 3mb');
    }

    reader.onloadend = () => {
      clearErrors();
      const tempFlow = { ...flow };
      const template = tempFlow.templates[temIndex];
      if (template) {
        template.image = file;
        template.imageB64 = reader.result as string;
        setFlow({ ...tempFlow });
      }
    }

    reader.readAsDataURL(file)
  }

  const setTemInput = (temIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    const tempFlow = { ...flow };
    const template = tempFlow.templates[temIndex];
    if (e.target.name === 'cardUrl') {
      // @ts-ignore
      template.action.url = e.target.value;
    } else {
      // @ts-ignore
      template[e.target.name.split('-')[0]] = e.target.value;
    }

    setFlow({ ...tempFlow });
  }

  const setAlwaysReply = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFlow({
      ...flow,
      alwaysReply: e.target.value === 'alwaysReply',
    });
  }

  const setInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setFlow({
      ...flow,
      [e.target.name]: e.target.value,
    });
  }

  const replaceLinks = () => {
    if (!isValidateUrl(replaceLink)) {
      setReplaceLinkError('This URL is not valid');
      return;
    }

    const tempFlow = { ...flow };
    
    tempFlow.templates.forEach(temp => {
      if (temp.action) {
        temp.action.url = replaceLink
      }
      temp.buttons?.forEach(btn => {
        btn.url = replaceLink
      })
    })

    setFlow(tempFlow);
    setReplaceLink('');
  }

  const onReplaceLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReplaceLink(e.target.value);
    setReplaceLinkError('');
  }

  const removeButton = (temIndex: number, btnIndex: number) => () => {
    clearErrors();
    const tempFlow = { ...flow };
    const template = tempFlow.templates[temIndex];
    if (template.buttons) {
      template.buttons = template.buttons.filter((_, i) => i !== btnIndex);
      setFlow({ ...tempFlow });
    }
  }

  const addButton = (temIndex: number) => () => {
    const tempFlow = { ...flow };
    const { buttons } = tempFlow.templates[temIndex];
    tempFlow.templates[temIndex].buttons = (buttons || []).concat([{ title: 'New Button', url: '' }]);
    setFlow({ ...tempFlow });
  }

  const remTemplate = (temIndex: number) => () => {
    if (!window.confirm('Delete this template?')) return;

    const tempFlow = { ...flow };
    tempFlow.templates.splice(temIndex, 1);

    setFlow({ ...tempFlow });
  }

  const addTemplate = (type: 'text' | 'generic') => () => {
    const tempFlow = { ...flow };
    const data: TFlowTemplate = {
      type,
      buttons: [],
    };
    if (type === 'text') {
      data.text = ''
    } else {
      data.title = '';
      data.subtitle = '';
      data.image = '';
      data.imageB64 = '';
      data.photo = '';
      data.action = {
        url: '',
      };
    }
    tempFlow.templates.push(data);

    setFlow(tempFlow);
    setTimeout(() => {
      window.scrollTo({
        top: window.document.body.scrollHeight + 200,
        behavior: 'smooth'
      });
    }, 100);
  }

  const setImageAspRatio = (temIndex: number, ratio: 'square' | 'horizontal') => () => {
    const tempFlow = { ...flow };
    const template = tempFlow.templates[temIndex];
    if (template) {
      template.imageAspectRatio = ratio;
    }
    setFlow({ ...tempFlow });
  }

  const saveFlow = async () => {
    if (flow.type === 'flow' && !flow.templates.length) {
      setError('You need to add at least one template.')
      return;
    }

    const tempErrors = Array(flow.templates.length);

    flow.templates.forEach((temp, index) => {
      const val = templateValidator.validate({
        ...temp,
        image: undefined,
        imageB64: undefined,
        photo: undefined,
      });
      if (val.error) {
        tempErrors[index] = val.error?.message;
        return;
      }
      if (temp.action?.url && !isValidateUrl(temp.action?.url)) {
        tempErrors[index] = 'cardUrl is not a valid url';
        return;
      }
      if (!temp.subtitle && !temp.buttons?.length && !temp.image && !temp.photo && temp.type === 'generic') {
        tempErrors[index] = 'One of subtitle, image or button is required.';
        return;
      }
      if (temp.type !== 'generic' && !temp.text && !isMenu) {
        tempErrors[index] = 'Message is required.';
        return;
      }
      temp.buttons?.forEach(({ url, title }) => {
        let err = '';
        if (!title) err = 'Button name is required.';
        if (!err && !url) err = `URL of "${title}" is required.`;
        if (!err && !isValidateUrl(url)) {
          err = `URL of "${title}" is not valid.`;
        }
        if (err) {
          tempErrors[index] = err;
          return;
        }
      })
    });

    let retn = false;
    if (tempErrors.filter(err => !!err).length) {
      retn = true;
      setTempError(tempErrors);
    }
    if (!flow.name) {
      retn = true;
      setNameError('Name is required.');
    }
    if (retn) {
      setError('Fix all errors')
      return;
    }

    const uploadImage = async (temp: TFlowTemplate, index: number) => {
      temp.photo = undefined;

      const formData = new FormData();
      formData.append('image', temp.image as Blob);

      const { data } = await apiCall.post('/media/upload', formData, {
        onUploadProgress: (ev) => {
          if (ev.progress !== undefined) {
            const percent = Math.floor(ev.progress * 100);
            const uPercents = [...uploadsPercent];
            uPercents[index] = percent;
            setUploadsPercent(uPercents);
          }
        }
      });

      temp.photo = data.image.url;
    }

    setIsSaving(true);
    try {
      const tempWUImage = flow.templates.filter(temp => {
        return temp.type !== 'text' && temp.image && typeof temp.image !== 'string';
      });

      if (tempWUImage.length) {
        setUploadsPercent(Array(tempWUImage.length).fill(0));
        await Promise.all(tempWUImage.map((temp, index) => uploadImage(temp, index)));
      }

      const flowData: TTFlow = {
        name: flow.name,
        pageId: activePage?.fbId as string,
        type: flow.type,
        alwaysReply: flow.alwaysReply,
        templates: flow.templates.map(({ title, subtitle, photo, text, type, buttons, action, imageAspectRatio, delay }, index) => ({
          title,
          subtitle,
          photo,
          text,
          type,
          imageAspectRatio: photo ? (imageAspectRatio || 'horizontal') : undefined,
          buttons: buttons?.length ? buttons?.map(({ url, title }) => ({ url, title })) : undefined,
          action: action?.url ? action : undefined,
          delay: (delay && index !== 0) ? (delay * 1000) : 0,
        })),
      };

      const url = isEdit ? `/flows/${params.flowId || props.type}` : '/flows';
      const method = isEdit ? 'put' : 'post';
      const { data } = await apiCall[method](url, flowData);

      if (isEdit && flow.type === 'default-reply') {
        setActivePage({
          ...activePage as IPage,
          alwaysReply: flow.alwaysReply,
        }, true)
      }

      toast.success('Flow saved successfully.');
      if (isFlow) {
        navigate(`/flows/${data.flow._id}?p=${activePage?.fbId}`);
      } else {
        navigate(`/flows?p=${activePage?.fbId}`);
      }
    } catch (error) {
      console.log(error);
      setError('Error occured, flow not created.')
    }
    setUploadsPercent([]);
    setIsSaving(false);
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const templates = Array.from(flow.templates);
    const [reorderedItem] = templates.splice(result.source.index, 1);
    templates.splice(result.destination.index, 0, reorderedItem);
    setFlow({ ...flow, templates });
  }

  const FlowContent = () => {
    return (
      <Row>
        <div className={"col-lg-8 row " + (isView ? 'flow-view' : '')}>
          <div className="w-100" style={{ position: 'relative' }}>
            <h1 className="ml-3">{typeTitles[props.type] || flow.name}</h1>
            {isEdit && flow.type === 'default-reply' && (
              <div style={{ position: 'absolute', right: 20, bottom: 0 }} className="replies row">
                <div className="mr-3">
                  <input onChange={setAlwaysReply} className="mr-1" type="radio" name="alwaysReply" id="alwaysReply" value="alwaysReply" defaultChecked={flow.alwaysReply} />
                  <label htmlFor="alwaysReply"><small>Always Reply</small></label>
                </div>
                <div>
                  <input onChange={setAlwaysReply} className="mr-1" type="radio" name="alwaysReply" id="12Hours" value="12Hours" defaultChecked={!flow.alwaysReply} />
                  <label htmlFor="12Hours"><small>Once in 12 hours</small></label>
                </div>
              </div>
            )}
          </div>
          <hr className="mt-0 ml-3 w-100" />
          <div className="col-lg-4 ">
            {(!isView && !isMenu) &&
              <>
                {isFlow && <div className={"mb-3 pb-3 flow-name"}>
                  <label className="h3" htmlFor="flowName">Flow Name:</label>
                  <input key="flowName" onChange={setInput} value={flow.name} className="ml-3" type="text" name="name" placeholder="Name" readOnly={readOnly} />
                  <div className="ml-3">.<small className="text-danger">{nameError}</small></div>
                </div>}
                <div className="mb-3">
                  <label className="h5">Click to add template:</label>
                  <div className="ml-3 d-flex">
                    <div onClick={addTemplate('generic')} className="add-temp mr-2">
                      <i className="fa fa-2x fa-credit-card"></i>
                      <span>+ Card</span>
                    </div>
                    <div onClick={addTemplate('text')} className="add-temp ml-2">
                      <i className="fa fa-2x fa-align-left"></i>
                      <span>+ Text</span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="mb-3">
                  <label className="h5">Replace All Links:</label>
                  <i title="This replaces all links in all cards" className="fa fa-question-circle ml-2" aria-hidden="true"></i>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <input style={{ fontSize: 12 }} value={replaceLink} onChange={onReplaceLink} className="w-100 p-2" type="url" placeholder="https://example.com" />
                    <div className="ml-1"><small className="text-danger">{replaceLinkError}</small></div>
                    <Button onClick={replaceLinks} variant="success" size="sm" className="px-4 my-2" style={{ alignSelf: 'end' }}>
                      <span> Replace</span>
                    </Button>
                  </div>
                </div>
                <hr />
                <div>
                  <h4>Hints:</h4>
                  <ul style={{ paddingLeft: 10 }}>
                    <li className="mb-2 text-center">
                      <small>You can add the following user tags: </small>
                      <small><strong>
                        {['First', 'Last', 'Full'].map(item => (
                          <span className="d-inline-block mr-2">{`{${item} Name}`} </span>
                        ))}
                      </strong></small>
                    </li>
                    {flow.type === "default-reply" && (<li className="mb-2 text-center">
                      <small>You can add delay on a template from the previous template using <i className="fa fa-clock"></i></small>
                    </li>)}
                  </ul>
                </div>
              </>
            }
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="col-lg-8 mx-auto">
              <FlowCard
                flow={flow}
                isView={isView}
                setImage={setImage}
                setButton={setButton}
                setInput={setTemInput}
                addButton={addButton}
                removeButton={removeButton}
                removeImage={removeImage}
                tempErrors={tempErrors}
                remTemplate={remTemplate}
                setImageAspRatio={setImageAspRatio}
              />
              <div>
                {!flow.templates.length && (
                  <div className="text-warning mt-5 text-center">Click on "Card" or "Text" on the left<br />to add a template</div>
                )}
              </div>
            </div>
          </DragDropContext>
        </div>

        <div className="col-lg-4 text-center">
          {
            isView ? <>
              <Link to={`/broadcasts/${params.flowId}?p=${activePage?.fbId}`} className="btn btn-success px-5 m-2">
                <i className="fa fa-bullhorn"></i>
                <span> Broadcast</span>
              </Link>
              <Link to={`/flows/${params.flowId}/edit?p=${activePage?.fbId}`} className="btn btn-primary px-5 m-2">
                <i className="fa fa-edit"></i>
                <span> Edit</span>
              </Link>
            </> : <>
              <Button onClick={saveFlow} variant="success" className="px-5 m-2" disabled={isSaving}>
                {isSaving && <i className="fa fa-spinner fa-spin"></i>}
                <i className="fa fa-save"></i>
                <span> Save</span>
              </Button>
              {!!uploadsPercent.length && (
                <div>
                  <p className="mb-1 mt-3"><small>Uploading image(s)</small></p>
                  {uploadsPercent.map((uPerc, index) => (
                    <div key={`up-${index}`}><progress color="green" value={uPerc} max={100} style={{ width: '200px' }}></progress></div>
                  ))}
                </div>
              )}
              {!!error && <div className={"text-danger mt-3"}><small>{error}</small></div>}
            </>
          }
        </div>
      </Row>
    )
  }

  const noContent = !flow.templates.length && !isTypedFlow;
  return (
    <div className="container-fluid content-area mt-3">
      {
        // Call FlowContent as function to avoid inputs loosing focus
        isLoading ? <CenterLoader /> : (noContent ? <NotFound /> : FlowContent())
      }
    </div>
  )
}

export default FlowManager;
