import React from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { AppContext, AppContextInterface } from '../../context';
import { apiCall } from '../../helpers/axios';
import logout from '../../helpers/logout';
import CopyFlow from './CopyFlow';
import ImportFlow from './ImportFlow';
import { useActivePage, SetActivePage } from '../../helpers/utils';
import { IPage } from '../../types/page';
import './Profile.css';

const Profile = () => {
  const { user, pages, setPages, isTestUser } = React.useContext(AppContext) as AppContextInterface;
  const activePage = useActivePage() as IPage;
  const navigate = useNavigate();

  const diconnectPage = (pageId: string) => async () => {
    const del = window.confirm('Page and all associated subscribers and broadcasts would be permanently deleted, continue?');
    if (!del) return;

    try {
      const { data } = await apiCall.post('/pages/disconnect', { pageId });
      const userPages = pages.filter(page => page.fbId !== pageId);
      setPages(userPages);
      if (activePage?.fbId === pageId && userPages.length) {
        SetActivePage(userPages[0]);
        const newLoc = window.location.search.replace(pageId, userPages[0].fbId);
        navigate(newLoc);
      }
      if (!userPages.length) {
        await logout(true);
        navigate('/login/facebook');
      }
      toast.success(data.message);
    } catch (error) {
      toast.error('Could not be completed');
      console.log(error);
    }
  }

  const deleteAll = async () => {
    const del = window.prompt('All your data would be permanently deleted, to continue, type DELETE');
    if (del !== 'DELETE') return;

    try {
      const { data } = await apiCall.post('/auth/delete-account');
      localStorage.clear();
      toast.success(data.message);
      navigate('/login/facebook');
    } catch (error) {
      toast.error('Could not be completed');
      console.log(error);
    }
  }

  const switchAccount = () => {
    if (!window.confirm('Switch to another facebook account?')) return;
    navigate('/login/facebook');
  }
 
  return <>
    <div className="profile header pb-6 d-flex align-items-center">
      <span className="mask bg-gradient-default opacity-8"></span>
      <div className="container-fluid d-flex align-items-center text-white">
        <div className="row">
          <div className="col-12">
            <h1 style={{lineHeight: 1}} className="display-2 text-white mb-0">
              <span className="d-inline">Hello {user?.firstName} {user?.lastName}</span>
              {!isTestUser && <button onClick={switchAccount} className="btn btn-sm btn-primary ml-3">Switch</button>}
            </h1>
            <p>{ user?.email && <small style={{fontSize: '60%'}}> ({user?.email})</small> }</p>
            <p className="text-white mt-0 mb-5">Thank you for using SuruChat. You can manage your pages and all your information here</p>
          </div>
        </div>
      </div>
    </div>

    <div className="row mt--6">
      <div className="col-md-10 ml-auto mr-auto">
        <div className="card card-upgrade">
          <div className="card-header text-center border-bottom-0">
            <h1 className="card-title mt-2">Manage Account</h1>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div className="row mt--2">
      <div className="col-md-10 ml-auto mr-auto">
        <div className="card card-upgrade">
          <CopyFlow pages={pages} />
          <ImportFlow userId={user?._id} pageId={activePage?.fbId} />
        </div>
      </div>
    </div>

    <hr />

    <div className="row mt--2">
      <div className="col-md-10 ml-auto mr-auto">
        <div className="card card-upgrade">
          <div className="card-body">
            <div className="table-responsive table-upgrade">
              <h3>Disconnect Pages</h3>
              <table className="table">
                <tbody>
                  {
                    pages.map((page, i) => (
                      <tr key={i}>
                        <td>{page.name}</td>
                        <td className="text-center">
                          <button onClick={diconnectPage(page.fbId)} className="btn btn-round btn-sm btn-warning">Disconnect</button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div className="row mt--2">
      <div className="col-md-10 ml-auto mr-auto">
        <div className="card card-upgrade">
          <div className="card-header border-bottom-0">
            <h3 className="card-title">Delete Account</h3>
          </div>
          <div className="card-body pt-1">
            <p>Delete account and all your data.</p>
            <button onClick={deleteAll} className="btn btn-round btn-danger text-default">Delete Account</button>
          </div>
        </div>
      </div>
    </div>

  </>
}

export default Profile;
