import { Droppable } from "react-beautiful-dnd";
import { TFlow } from "../../../types/page";
import FlowTemplate from "./FlowTemplate";

const FlowCard = (props: {
  flow: TFlow;
  isView: boolean;
  setImage?: (temIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  setInput?: (temIndex: number) => (e: any) => void;
  removeButton?: (temIndex: number, i: number) => (() => void);
  setButton?: (temIndex: number, i: number, key: 'title' | 'url') => ((e: React.ChangeEvent<HTMLInputElement>) => void);
  addButton?: (temIndex: number) => () => void;
  removeImage?: (temIndex: number) => (e: any) => void;
  remTemplate?: (temIndex: number) => () => void;
  setImageAspRatio?: (temIndex: number, ratio: 'square' | 'horizontal') => (e: any) => void;
  tempErrors?: string[],
}) => {
  const {
    flow,
    isView = false,
    tempErrors = [],
    setImage,
    setInput = () => () => { },
    removeButton = () => () => { },
    setButton = () => () => { },
    addButton,
    removeImage,
    remTemplate,
    setImageAspRatio,
  } = props;
  const isMenu = flow.type === 'menu';

  return (
    <Droppable isDropDisabled={isView} droppableId="fl-templates">
      {provided => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <div className="col-lg-10 mx-auto">
            {flow.templates.map((template, temIndex) => (
              <FlowTemplate
                key={`fl--card-${temIndex}`}
                template={template}
                temIndex={temIndex}
                isMenu={isMenu}
                isView={isView}
                setImage={setImage}
                setButton={setButton}
                setInput={setInput}
                addButton={addButton}
                removeButton={removeButton}
                removeImage={removeImage}
                tempErrors={tempErrors}
                remTemplate={remTemplate}
                setImageAspRatio={setImageAspRatio}
                canDrag={flow.templates.length > 1}
              />
            ))}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default FlowCard;
