import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, FormCheck, Modal, Row } from 'react-bootstrap';
import { AppContext, AppContextInterface } from '../../context';
import { apiCall } from '../../helpers/axios';
import { IFlow, TFlow } from '../../types/page';
import FlowCard from '../Flow/FlowCard/FlowCard';
import CenterLoader from '../Loader/Loader2';
import { toast } from 'react-toastify';
import { DragDropContext } from 'react-beautiful-dnd';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment, { MomentInput } from 'moment';
import { useActivePage } from '../../helpers/utils';

const BroadcastMixer: React.FC = () => {
  const activePage = useActivePage();
  const { pages } = React.useContext(AppContext) as AppContextInterface;
  const [flow, setFlow] = React.useState<IFlow | null>(null);
  const [flows, setFlows] = React.useState<IFlow[]>([]);
  const [flowIds, setFlowIds] = React.useState<string[]>([]);
  const [pageIds, setPageIds] = React.useState<string[]>([]);
  const [show, setShow] = React.useState(false);
  const [flowPage, setFlowPage] = React.useState(1);
  const [canLoadMore, setCanLoadMore] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingFlow, setIsLoadingFlow] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [shuffle, setShuffle] = React.useState(false);
  const [startAt, setStartAt] = React.useState(moment());
  const [endAt, setEndAt] = React.useState(moment());
  const [intervalDate, setIntervalDate] = React.useState(moment().set({ h: 2, m: 0, s: 0 }));
  const navigate = useNavigate();

  const mixBroadcast = async () => {
    try {
      setIsSaving(true);
      if (moment().diff(startAt, 'milliseconds') > 0) {
        return toast.error('Cannot schedule for a past time.');
      }
      if (moment(startAt).diff(endAt, 'milliseconds') > 0) {
        return toast.error('End time is before start time.');
      }

      const { hours, minutes } = moment(intervalDate).toObject();
      const interval = hours + (Math.ceil(minutes * 100 / 60) / 100);
      if (interval < 0.5) {
        return toast.error('At least 30 minutes interval is required.');
      }
      if (!flowIds.length) {
        return toast.error('You need to select flows.');
      }
      if (!pageIds.length) {
        return toast.error('You need to select pages.');
      }

      const { data } = await apiCall.post(`/broadcasts/mix`, {
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        interval,
        shuffle,
        pageIds,
        flowIds,
      });
      toast.success(data.message);
      navigate(`/broadcasts?p=${activePage?.fbId}`);
    } catch (error) {
      console.log(error);
      toast.error('Error coccurred while processing.');
    } finally {
      setIsSaving(false)
    }
  }

  const onSchedule = (type: string, dDate: MomentInput, setDate: Function) => (e: any) => {
    const newSendAt = moment(dDate);
    const { years, months, date, hours, minutes } = moment(type === 'date' ? e.target.value : e).toObject();
    const seconds = 0;

    if (type === 'date') {
      newSendAt.set({ years, months, date, seconds });
    } else {
      newSendAt.set({ hours, minutes, seconds });
    }

    setDate(newSendAt);
  }

  const loadMore = () => {
    setIsLoadingFlow(true);
    getFlows(flowPage + 1).then(data => {
      setFlows([...flows, ...data]);
    }).finally(() => {
      setIsLoadingFlow(false);
    });
  }

  const getFlows = async (pageNum = 1) => {
    try {
      const { data } = await apiCall.get('/flows', { params: { page: pageNum } });
      const { limit, page, totalCount } = data.metadata;
      const totalPages = Math.ceil(totalCount / limit);
      setFlowPage(page);
      setCanLoadMore(page < totalPages);
      return data.flows;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  const selectPage = (id: string) => () => {
    const ids = pageIds.includes(id) ? pageIds.filter(pId => pId !== id) : [...pageIds, id];
    setPageIds(ids);
  }

  const selectFlow = (id: string) => () => {
    const index = flowIds.indexOf(id);
    const ids = index >= 0 ? flowIds.filter(fId => fId !== id) : [...flowIds, id];
    setFlowIds(ids);
  }

  React.useEffect(() => {
    if (activePage) {
      setPageIds([...pageIds, activePage?._id]);
    }
    let isCancelled = false;

    getFlows().then(data => {
      const flows = data as IFlow[];
      if (!isCancelled && flows.length) {
        setFlows(flows);
      }
      setIsLoading(false);
      isCancelled = false;
    });

    return () => {
      isCancelled = true;
    };
  }, [activePage?.fbId])

  const handleShow = (flow: IFlow) => () => {
    setFlow(flow);
    setShow(true);
  }
  const FlowModal = () => {
    const handleClose = (selected = false) => () => {
      setShow(false);
      if (selected) {
        selectFlow(flow?._id as string)();
      }
      setFlow(null);
    }
    const checked = flowIds.includes(flow?._id as string);

    return (
      <>
        <Modal show={show} onHide={handleClose()}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center"><h2>{flow?.name}</h2></Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-0">
            <DragDropContext onDragEnd={() => { }}>
              <div className="mx-auto">
                <FlowCard flow={flow as TFlow} isView={true} />
              </div>
            </DragDropContext>
          </Modal.Body>
          <Modal.Footer>
            <a href={`/flows/${flow?._id}?p=${activePage?.fbId}`} className="mr-auto">
              <small>Goto flow</small>
            </a>
            <Button size="sm" variant="secondary" onClick={handleClose()}>
              Close
            </Button>
            <Button size="sm" variant={checked ? 'warning' : 'primary'} onClick={handleClose(true)}>
              {checked ? 'Deselect' : 'Select'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const BroadcastPage = () => {
    return <>
      <h1>Schedule Mixer</h1><hr className="my-3" />
      <Row className="mt-4 mix-panel">
        <Col md="6">
          <h3 className="ml-3">Select Pages ({pageIds.length})</h3>
          <div>{
            pages.map(page => <FormCheck
              className="brd-btm"
              checked={pageIds.includes(page._id)}
              key={page._id}
              id={page._id}
              type="checkbox"
              label={page.name}
              onChange={selectPage(page._id)}
            />)
          }</div>
        </Col>
        <Col md="6">
          <h3 className="ml-3">Select Flows ({flowIds.length})</h3>
          <div>
            {
              flows.map(flow => {
                const cIndex = flowIds.indexOf(flow._id);
                return <div className="brd-btm" key={flow._id}>
                  <span className='sel-flow-num'>{cIndex >= 0 ? cIndex + 1 : ''}</span>
                  <FormCheck
                    checked={flowIds.includes(flow._id)}
                    className="d-inline mr-2"
                    id={flow._id}
                    type="checkbox"
                    label={flow.name}
                    onChange={selectFlow(flow._id)}
                  />
                  <a className="ml-auto mr-2" href="#" onClick={handleShow(flow)}><small>view</small></a>
                </div>
              })
            }
            {canLoadMore && <div className="mt-2">
              <a onClick={loadMore} href="#"><small><em>Load more...</em></small></a>
              {isLoadingFlow && <i className="fa fa-spinner fa-spin"></i>}
            </div>}
          </div>
        </Col>
      </Row>

      <Row className="mt-3 pt-3">
        <Col md="4">
          <span>Start At</span>
          <div className="my-2">
            <span>Date: </span>
            <input
              onChange={onSchedule('date', startAt, setStartAt)}
              className="date-inp"
              type="date"
              defaultValue={startAt.format('YYYY-MM-DD')}
            />
          </div>
          <div>
            <span>Time: </span>
            <TimePicker
              minuteStep={5}
              onChange={onSchedule('time', startAt, setStartAt)}
              allowEmpty={false}
              showSecond={false}
              use12Hours={true}
              defaultValue={startAt}
            />
          </div>
        </Col>
        <Col md="4">
          <span>End At</span>
          <div className="my-2">
            <span>Date: </span>
            <input onChange={
              onSchedule('date', endAt, setEndAt)}
              className="date-inp"
              type="date"
              defaultValue={endAt.format('YYYY-MM-DD')}
            />
          </div>
          <div>
            <span>Time: </span>
            <TimePicker
              minuteStep={5}
              onChange={onSchedule('time', endAt, setEndAt)}
              allowEmpty={false}
              showSecond={false}
              use12Hours={true}
              defaultValue={endAt}
            />
          </div>
        </Col>
        <Col md="4" className="pt-3">
          <Row className="mb-2">
            <span>Interval <small>(HH:mm)</small></span>
            <Col md="6">
              <TimePicker
                minuteStep={5}
                onChange={onSchedule('time', intervalDate, setIntervalDate)}
                allowEmpty={false}
                showSecond={false}
                defaultValue={intervalDate}
              />
            </Col>
          </Row>
          <Row>
            <FormCheck onChange={() => setShuffle(!shuffle)} className="mr-1" id="suffle" label="Shuffle?" />
            <small style={{ marginTop: 3 }}>(Send as selected or shuffle)</small>
          </Row>
        </Col>
        <Col className="text-right mr-5">
          <hr />
          <Button disabled={isSaving} onClick={mixBroadcast} className="px-5" size="lg" variant="success">
            SCHEDULE
            {isSaving && <i className="fa fa-spinner fa-spin ml-1"></i>}
          </Button>
        </Col>
      </Row>
      <FlowModal />
    </>
  }

  return (
    <div className="container-fluid content-area mt-3">
      {
        isLoading ? <CenterLoader /> : BroadcastPage()
      }
    </div>
  )
}

export default BroadcastMixer;
