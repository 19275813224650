import * as React from 'react';
import './Loader.css';

const Loader: React.FC<{ error: boolean }> = (props: React.PropsWithChildren<{ error: boolean }>) => {
  const { error } = props;
  const url = window.location.href;

  return (
    <div className="loader-cont">
      {
        error ? (
          <div className="text-center bg-dar px-7 py-3">
            <h3 className="text-white">An error has occurred!</h3>
            <a href={url} className="btn btn-neutral btn-icon">
              <span className="btn-inner--text">Retry</span>
            </a>
          </div>
        ) : (
          <span>
            <i className="fa fa-life-ring fa-spin fa-3x"></i>
          </span >
        )
      }
    </div>
  )
}

export default Loader;
