import * as React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import { TFlowTemplate } from '../../../types/page';
import FlowTextarea from './FlowTextarea';
import 'react-popper-tooltip/dist/styles.css';
import '../Flow.css';


const FlowTemplate = (props: {
  isMenu: boolean;
  isView: boolean;
  temIndex: number;
  template: TFlowTemplate;
  setImage?: (temIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  setInput?: (temIndex: number) => (e: any) => void;
  removeButton?: (temIndex: number, i: number) => (() => void);
  setButton?: (temIndex: number, i: number, key: 'title' | 'url') => ((e: React.ChangeEvent<HTMLInputElement>) => void);
  addButton?: (temIndex: number) => () => void;
  removeImage?: (temIndex: number) => (e: any) => void;
  setImageAspRatio?: (temIndex: number, ratio: 'square' | 'horizontal') => (e: any) => void;
  remTemplate?: (temIndex: number) => () => void;
  tempErrors: string[];
  canDrag: boolean;
}) => {
  const {
    temIndex, template,
    isView = false,
    tempErrors,
    isMenu,
    canDrag,
    setImage,
    setInput = () => () => { },
    removeButton = () => () => { },
    setButton = () => () => { },
    addButton,
    removeImage,
    remTemplate,
    setImageAspRatio,
  } = props;
  const isTextFlow = ['text', 'button'].includes(template.type);
  const getFlowImg = () => {
    return template.imageB64 || (template.image as string) || template.photo;
  }

  const getPopover = (temIndex: number, btnIndex: number) => {
    const buttons = template?.buttons;
    const button = buttons && buttons[btnIndex];
    if (!button) return <></>;

    return (
      <Popover id={`btn-popup-${btnIndex}`} key={`btn-popup-${btnIndex}`} className={isView ? 'flow-view' : ''}>
        <Popover.Title as="h3">Button Setup <i onClick={removeButton(temIndex, btnIndex)} className="remove-btn text-danger fa fa-trash"></i></Popover.Title>
        <Popover.Content>
          <input value={button.title} onChange={setButton(temIndex, btnIndex, 'title')} placeholder="Enter button name" className="mb-2 w-100" readOnly={isView} />
          <input value={button.url} onChange={setButton(temIndex, btnIndex, 'url')} placeholder="Enter button url" className="mb-2 w-100" readOnly={isView} />
        </Popover.Content>
      </Popover>
    )
  }

  const fileRef = React.createRef<HTMLInputElement>();
  const resetImage = (temIndex: number) => (e: any) => {
    e.preventDefault();
    if (removeImage) {
      removeImage(temIndex)(e);
    }
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  }

  const cardUrlPopper = (temIndex: number) => {
    return (
      <Popover id={`imgPop-${temIndex}`} key={`imgPop-${temIndex}`} className={isView ? 'flow-view' : ''}>
        <Popover.Title as="h3">Card URL</Popover.Title>
        <Popover.Content>
          <input
            placeholder="Enter url"
            onChange={setInput(temIndex)}
            value={template.action?.url}
            name="cardUrl"
            className="mb-2 w-100"
            readOnly={isView} />
        </Popover.Content>
      </Popover>
    )
  }

  const delayPopper = (temIndex: number) => {
    return (
      <Popover id={`imgPop-${temIndex}`} key={`imgPop-${temIndex}`} className={isView ? 'flow-view' : ''}>
        <Popover.Title as="h3">Delay (seconds)</Popover.Title>
        <Popover.Content>
          <input
            type="number"
            defaultValue={template.delay || 0}
            onChange={setInput(temIndex)}
            name="delay"
            className="mb-2 w-100"
            readOnly={isView} />
        </Popover.Content>
      </Popover>
    )
  }

  return (
    <Draggable isDragDisabled={isView} index={temIndex} draggableId={`flcard-${temIndex}`}>
      {(provided) => (
        <div {...provided?.draggableProps} className="flow-card mb-3" ref={provided?.innerRef}>
          <div>
            <div className="card mb-0">
              {!isTextFlow && (getFlowImg() || (!isView && !isMenu)) && (
                <div style={{ backgroundImage: getFlowImg() ? `url("${getFlowImg()}")` : 'none' }} className={`nimg ${template.imageAspectRatio || 'horizontal'}`}>
                  {!isView && getFlowImg() && (
                    <>
                      <div className="img-contr">
                        <i onClick={resetImage(temIndex)} style={{ color: 'red' }} className="fa fa-times"></i>
                      </div>
                      <div className="img-contr lft">
                        {setImageAspRatio && (
                          <div className="asp-ratio" style={{ background: '#00000021', borderRadius: 5 }}>
                            <span><i onClick={setImageAspRatio(temIndex, 'horizontal')} className="fas fa-tv"></i></span>
                            <span><i onClick={setImageAspRatio(temIndex, 'square')} className="far fa-square"></i></span>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {!getFlowImg() && (
                    <div className="img-up">
                      <i className="fa fa-2x fa-image text-muted"></i>
                      <label htmlFor={`cardImage-${temIndex}`} className="mt-2"><small>Upload Image</small></label>
                    </div>
                  )}
                  {(!isView || template.action?.url) && !isTextFlow && (
                    <div className="link-contr">
                      <OverlayTrigger trigger="click" placement="right" overlay={cardUrlPopper(temIndex)} rootClose>
                        <i className="fa fa-link"></i>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
              )}
              <input ref={fileRef} id={`cardImage-${temIndex}`} type="file" onChange={setImage && setImage(temIndex)} accept="image/*" hidden name="image" />
              <div className="card-body pb-3">
                {!isMenu && (
                  <>
                    <FlowTextarea
                      isView={isView}
                      setInput={setInput}
                      temIndex={temIndex}
                      name="title"
                      value={template.title}
                      placeholder="Message title"
                      taClass="py-2 h4"
                      hidden={isTextFlow}
                    />
                    <FlowTextarea
                      isView={isView}
                      setInput={setInput}
                      temIndex={temIndex}
                      name={isTextFlow ? "text" : "subtitle"}
                      value={isTextFlow ? template.text : template.subtitle}
                      placeholder={`Message ${isTextFlow ? '' : 'subtitle'}`}
                      taClass="pt-2 pb-3"
                      hidden={isView && !template.subtitle && !template.text}
                    />
                  </>
                )}
                <div className="mb--3 flow-btns text-center" style={{ position: 'relative' }}>
                  <p className="card-btns">
                    {
                      template.buttons?.map(({ title }, i) => (
                        <OverlayTrigger key={`btnPop-${i}`} trigger="click" placement="right" overlay={getPopover(temIndex, i)} rootClose>
                          <button className="card-btn h5 m-0 p-1" id={`btnPop-btn-${i}`} type="button">{title}</button>
                        </OverlayTrigger>
                      ))
                    }
                  </p>
                  {(template.buttons || [])?.length < 3 && !isView && (
                    <p className="addBtn py-1">
                      <button className="add-btn text-muted" onClick={addButton && addButton(temIndex)}>
                        <small><span className="fa fa-plus"></span> Add button</small>
                      </button>
                    </p>
                  )}
                  {!!temIndex && (<span title={`Delayed: ${template.delay || 0} seconds`} style={{ position: 'absolute', right: 7, bottom: 7, cursor: 'pointer' }}>
                    <OverlayTrigger trigger="click" placement="right" overlay={delayPopper(temIndex)} rootClose>
                      <i className="fa fa-clock text-muted"></i>
                    </OverlayTrigger>
                  </span>)}
                </div>
              </div>
            </div>
            {tempErrors[temIndex] && <div className="text-center"><small className="text-danger">{tempErrors[temIndex]}</small></div>}
          </div>
          {!isView && !isMenu && (
            <div className="controls">
              <span className="rem" onClick={remTemplate && remTemplate(temIndex)}><i className="fa fa-times"></i></span>
              {canDrag && <span {...provided?.dragHandleProps} className="mov"><i className="fa fa-arrows-alt-v"></i></span>}
            </div>
          )}
        </div>
      )}
    </Draggable>
  )
}

export default FlowTemplate;
