import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './index.css';
import App from './App';
import Login from './components/Auth/Login';
import reportWebVitals from './reportWebVitals';
import AppContextProvider from './context/index';
import AuthCallback from './components/Auth/AuthCallback';
import Privacy from './components/Misc/Privacy';
import TermsCondition from './components/Misc/Terms';
import 'react-toastify/dist/ReactToastify.css';
import LoginFB from './components/Auth/LoginFB';
import Password from './components/Auth/Password';
import NotFound from './components/404/NotFound';

ReactDOM.render(
  // <React.StrictMode>
  <AppContextProvider>
    <BrowserRouter>
      <Routes>
        <Route path='/terms-and-condition' element={<TermsCondition />} />
        <Route path='/privacy-policy' element={<Privacy />} />
        <Route path='/auth-callback/*' element={<AuthCallback />} />
        <Route path='/password-reset' element={<Password />} />
        <Route path='/login'>
          <Route path='' element={<Login />} />
          <Route path='facebook' element={<LoginFB />} />
        </Route>
        <Route path='/*' element={<App />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  </AppContextProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
