import * as React from 'react';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { apiCall } from '../../helpers/axios';
import { ISubscriber, IPage } from '../../types/page';
import CenterLoader from '../Loader/Loader2';
import { useNavigate } from 'react-router';
import { useActivePage } from '../../helpers/utils';

const Subscriber: React.FC = () => {
  const [subscribers, setSubscribers] = React.useState([] as ISubscriber[]);
  const [metadata, setMetadata] = React.useState({ limit: 20, page: 1, totalCount: 0 });
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingMore, setIsLoadingMore] = React.useState(true);
  const navigate = useNavigate();
  const activePage = useActivePage();

  const getSubscribers = async (page: number = 1, limit: number = 20) => {
    try {
      setIsLoadingMore(true);
      const query = new URLSearchParams({
        limit: String(limit),
        page: String(page),
        pageId: (activePage as IPage).fbId,
      });

      const { data: { subscribers, metadata } } = await apiCall.get(`/subscribers?${query.toString()}`);

      setSubscribers(subscribers)
      setMetadata(metadata || {});
      setIsLoading(false);
      setIsLoadingMore(false);
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
    }
  }

  const params = new URLSearchParams(window.location.search);
  const pageNum = Number(params.get('page'));
  React.useEffect(() => {
    if (!activePage) return;
    getSubscribers(pageNum || 1).then();
  }, [pageNum, activePage?.fbId]);

  const handlePageChange = (page: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', String(page))
    navigate({ search: params.toString() });
    setMetadata({ ...metadata, page });
    getSubscribers(page).then();
  }

  const SubscriberPage = () => {
    return (
      <>
        <div className="card">
          <div className="card-header border-0">
            <div className="row">
              <div className="col-6">
                <h3 className="mb-0">Subscribers</h3>
              </div>
              <div className="col-6 text-right">
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Created At</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  subscribers.length ?
                    subscribers.map((subscriber, i) => {
                      return <tr key={i}>
                        <td className="table-user">
                          <img src={subscriber.photo} alt="" className="avatar rounded-circle mr-3" />
                          <h4 className="font-weight-bold d-inline">
                            {subscriber.firstName} {subscriber.lastName}
                          </h4>
                        </td>
                        <td>
                          <span className="text-muted">
                            {moment(subscriber.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                          </span>
                        </td>
                        <td className="table-actions"></td>
                      </tr>
                    })
                    :
                    <tr className="table-user">
                      <td colSpan={3}><span className="text-muted">No records found</span></td>
                    </tr>
                }
              </tbody>
            </table>

            <hr className="mb-1" />

            <div className="p-3 paging">
              {isLoadingMore && <i className="fas fa-circle-notch fa-spin"></i>}
              <Pagination
                activePage={metadata.page}
                itemsCountPerPage={metadata.limit}
                totalItemsCount={metadata.totalCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination justify-content-end"
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="container-fluid content-area mt-3">
      {
        isLoading ? <CenterLoader /> : <SubscriberPage />
      }
    </div>
  )
}

export default Subscriber;
