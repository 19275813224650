import * as React from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { AppContext, AppContextInterface } from '../../context';
import { useActivePage } from '../../helpers/utils';
import logout from "../../helpers/logout";
import './Sidebar.css';

const Sidebar: React.FC = () => {
  const { pages, isTestUser } = React.useContext(AppContext) as AppContextInterface;
  const [loggingOut, setLoggingOut] = React.useState(false);
  const activePage = useActivePage() || pages[0];
  const navigate = useNavigate();

  const logoutUser = async (e: any) => {
    e.preventDefault();
    setLoggingOut(true);
    
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      toast.error('Could not be completed');
    }

    setLoggingOut(false);
  }

  return (
    <nav className="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main">
      <div className="scrollbar-inner">
        {/* Brand  */}
        <div className="sidenav-header d-flex align-items-center">
          <a className="navbar-brand" href="/">
            <img src="/assets/img/brand/suruchat-logo.svg" className="navbar-brand-img" alt="..." />
          </a>
          <div className="ml-auto">
          </div>
        </div>
        <div className="navbar-inner">
          {/* Collapse */}
          <div className="collapse navbar-collapse" id="sidenav-collapse-main">
            {/* Nav items */}
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className={({ isActive }) => `nav-link ${isActive ? 'active': ''}`} to={`/dashboard?p=${activePage?.fbId}`}>
                  <i className="ni ni-shop text-primary"></i>
                  <span className="nav-link-text">Dashboard</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={({ isActive }) => `nav-link ${isActive ? 'active': ''}`} to={`/broadcasts?p=${activePage?.fbId}`}>
                  <i className="fa fa-bullhorn text-green"></i>
                  <span className="nav-link-text">Broadcasts</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={({ isActive }) => `nav-link ${isActive ? 'active': ''}`} to={`/flows?p=${activePage?.fbId}`}>
                  <i className="ni ni-archive-2 text-default"></i>
                  <span className="nav-link-text">Flows</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={({ isActive }) => `nav-link ${isActive ? 'active': ''}`} to={`/subscribers?p=${activePage?.fbId}`}>
                  <i className="fa fa-users text-danger"></i>
                  <span className="nav-link-text">Subscribers</span>
                </NavLink>
              </li>
            </ul>
            
            <ul className="navbar-nav mb-md-3 mt-auto">
              {!isTestUser && <li className="nav-item">
                <NavLink className={({ isActive }) => `nav-link py-2 ${isActive ? 'active': ''}`} to={`/broadcasts/mixer?p=${activePage?.fbId}`}>
                  <i className="fa fa-magic text-primary"></i>
                  <span className="nav-link-text">Broadcast Mixer</span>
                  <span className="badge badge-sm badge-warning ml-1">Beta</span>
                </NavLink>
              </li>}
              <li>
                <hr className="my-2 hr-sep" />
              </li>
              <li className="nav-item">
                <NavLink className={({ isActive }) => `nav-link py-2 ${isActive ? 'active': ''}`} to={`/profile?p=${activePage?.fbId}`}>
                  <i className="fa fa-user text-default"></i>
                  <span className="nav-link-text">Profile</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <a onClick={logoutUser} role='button' href="!#" className="nav-link">
                  <i className={(loggingOut ? 'fa-pulse' : '') + ` fa fa-power-off text-warning`}></i>
                  <span className="nav-link-text">Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Sidebar;
