import * as React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AppContext, AppContextInterface } from '../../context';
import { useActivePage } from '../../helpers/utils';
import './TopNav.css';

const TopNav: React.FC = () => {
  const { pages, setActivePage } = React.useContext(AppContext) as AppContextInterface;
  const activePage = useActivePage();
  const navigate = useNavigate();
  const location = useLocation();

  const changeActivePage = (pageId: string) => (e: any) => {
    e.preventDefault()
    const activePageId = activePage?.fbId;
    const page = pages.find(page => page.fbId === pageId);

    if (page) {
      const newLoc = location.search.replace(activePageId as string, page.fbId);
      setActivePage(page);
      navigate(newLoc);
    }
  }
  const showPageDropdown = !['/broadcasts/mixer'].includes(window.location.pathname);

  const populatePages = () => {
    const iPages = [...pages].filter(page => page.fbId !== activePage?.fbId);

    return (
      <>
        {
          iPages.map((page, i) => (
            <a key={i} onClick={changeActivePage(page.fbId)} href="#!" className="list-group-item list-group-item-action">
              <div className="row align-items-center">
                <div className="col-auto">
                  <img alt="Image placeholder" src={page.photo} className="avatar rounded-circle" />
                </div>
                <div className="col ml--2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="mb-0 text-sm">{page.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          ))
        }
      </>
    )
  }

  return (
    <nav className="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">

          {showPageDropdown && <div className="nav-item dropdown">
            {pages.length ? (
              <a className="nav-link page-select" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="media align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="Image placeholder" src={activePage?.photo} />
                  </span>
                  <div className="media-body ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm text-white  font-weight-bold">{activePage?.name}</span>
                    <i className="ml-3 ni ni-bold-down text-white"></i>
                  </div>
                </div>
              </a>
            ) : (<div></div>)}
            <div className="dropdown-menu dropdown-menu-xl dropdown-menu-left py-0 overflow-hidden">
              <div className="px-3 py-3">
                <h6 className="text-sm text-muted m-0">You have connected <strong className="text-primary">{pages.length}</strong> page(s).</h6>
              </div>
              {/* List group */}
              <div className="list-group list-group-flush page-list">
                {populatePages()}
              </div>

              <Link to="/connect-pages" className="d-inline-block m-2 ml-5">Connect more...</Link>
            </div>
          </div>}

          {/* Navbar links */}
          <ul className="navbar-nav align-items-center ml-md-auto">
            <li className="nav-item d-xl-none">
              {/* Sidenav toggler */}
              <div className="pr-3 sidenav-toggler sidenav-toggler-dark" data-action="sidenav-pin" data-target="#sidenav-main">
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default TopNav;
