import { useState } from "react";
import { FormCheck, FormControl, Button, Row, Col } from 'react-bootstrap';
import { toast } from "react-toastify";
import { apiCall } from "../../helpers/axios";

const ImportFlow = (props: {
  userId?: string;
  pageId?: string;
}) => {
  const { pageId, userId } = props;
  const copyItemsArr = [
    { key: 'welcome-msg', title: 'Welcome Message' },
    { key: 'menu', title: 'Menu' },
    { key: 'default-reply', title: 'Default Reply' },
    { key: 'flow', title: 'All Flows' },
  ];

  const [error, setError] = useState('');
  const [flowUrl, setFlowUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [copyItems, setCopyItems] = useState<string[]>([]);

  const copyItemsChange = (key: string) => (e: any) => {
    let items = [...copyItems];

    if (e.target.checked) {
      items.push(key);
    } else {
      items = items.filter(val => val !== key);
    }

    setError('');
    setCopyItems(items);
  }

  const setInput = (e: any) => {
    setError('');
    setFlowUrl(e.target.value);
  }

  const importFlow = async () => {
    setError('');

    if (!flowUrl) {
      setError('Flow url is required.');
      return;
    }
    if (!copyItems.length) {
      setError('You need to select at least one import type.');
      return;
    }
    const [copyFromUserId, copyFromPageId] = (flowUrl.split('/sf/')[1] || '').split('/');
    if (!copyFromUserId || !copyFromPageId) {
      setError('Invalid flow url.');
      return;
    }

    const copyData = {
      copyFromUserId,
      copyFromPageId,
      copyToPageId: pageId,
      copyItems,
    };

    setLoading(true);
    try {
      const { data } = await apiCall.post('/flows/import', copyData);
      toast.success(data.message);
    } catch (error) {
      toast.error((error as any).response?.data?.error || 'Could not be completed');
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="card-body pt-0">
      <hr className="mt-0" />
      <Row>
        <Col className="col-12 col-md-6 import-flow">
          <h3>Import Flows</h3>
          <p className="mb-1">This allows you to import flows from a different user account.</p>
          <FormControl onChange={setInput} defaultValue={flowUrl} placeholder="Enter flow url" size="sm" className="my-3" />
          <div className="text-sm text-center ml-3">
            <strong className="mr-1" style={{ verticalAlign: 'top'}}>Import:</strong>
            {copyItemsArr.map(({ key, title }) => (
              <FormCheck inline
                type='checkbox'
                id={`imp-check-${key}`}
                key={`imp-check-${key}`}
                label={title}
                onChange={copyItemsChange(key)}
              />
            ))}
            <p className="im-note text-default mt-2 mb-1">
              <small><strong>Note:</strong> Importing Welcome Message, Default Reply or Menu will overwrite the existing templates on ONLY the active page.</small>
            </p>
            <div className="text-center">
            <p className="mb-1 text-white">{error && <small className="text-danger">{error}</small>}.</p>
              <Button onClick={importFlow} className="px-4" variant="success" size="sm">
                {loading && <i className="fa fa-spinner fa-spin"></i>}
                <span> Import</span>
              </Button>
            </div>
          </div>
        </Col>
        <Col className="share-flow col-12 col-md-6">
          <h3>Share Flows</h3>
          <p className="mb-1">This allows you to share flows to a different user account.</p>
          <code className="my-3">{`https://suruchat.com/sf/${userId}/${pageId}`}</code>
          <p className="mb-1 text-center text-sm">Copy and share the flow url above to share flows.</p>
        </Col>
      </Row>
    </div>
  )
}

export default ImportFlow;
