import { apiCall } from "./axios";

const logout = async (onlyUser = false) => {
  try {
    await apiCall.post('/auth/logout', { onlyUser });
    localStorage.clear();
  } catch (error) {
    console.log(error);
  }
}

export default logout;
