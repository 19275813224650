import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext, AppContextInterface } from '../../context';
import { apiCall } from '../../helpers/axios';
import Loader from '../Loader/Loader';

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const { setUser, setPages, setActivePage } = React.useContext(AppContext) as AppContextInterface;

  const authorizeUser = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const stateStr = queryParams.get('state');
      const code = queryParams.get('code');
      const fbId = queryParams.get('fbId');

      let state: Record<string, string | boolean> = {};
      if (stateStr) {
        try {
          state = JSON.parse(stateStr);
        } catch (_err) { }
      }

      const { data: { user, warning, connectedPages } } = await apiCall.post(`/auth/facebook${code ? '/authorize' : ''}`, { code, state, fbId });
      setUser(user);
      setPages(connectedPages);
      if (state.connect || !connectedPages.length) {
        return navigate(`/connect-pages?${warning ? 'warning=' + warning : ''}`);
      }

      const page = connectedPages[0];
      setActivePage(page);
      return navigate(`/dashboard?p=${page.fbId}`);
    } catch (err: any) {
      console.log(err)
      const message = err.response?.data?.error || 'Error validating code';
      return navigate(`/login/facebook?error=${message}`);
    }
  }

  React.useEffect(() => {
    authorizeUser();
  }, []);

  return <Loader error={false} />
}

export default AuthCallback;
