import axios, { AxiosError } from 'axios'
import { API_BASE_URL } from '../constants';

export const apiCall = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

const logout = (relogin = false) => {
  localStorage.removeItem('__USER__');
  window.location.href = `/login${relogin ? '?error=You need to re-login.' : ''}`;
}

apiCall.interceptors.response.use(data => data, (error: AxiosError) => {
  if (error.response?.status === 401) {
    console.log(error.response);
    logout(true);
  }
  throw error;
});
